/*
 * React wiring tutorial:
 * https://medium.freecodecamp.org/part-1-react-app-from-scratch-using-webpack-4-562b1d231e75
 */
import React from "react";
import ReactDOM from "react-dom";
/*
 * Router tutorial:
 * https://medium.com/@pshrmn/a-simple-react-router-v4-tutorial-7f23ff27adf
 */

// redux
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer, rootEpic } from './redux'

// observable
import { createEpicMiddleware } from 'redux-observable';

// app
import './index.css';
import { Main } from './pages';

// redux context
const epicMiddleware = createEpicMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);

const Index = () => (
    <Provider store={store}>
        <Main />
    </Provider>
);

ReactDOM.render(<Index />, document.getElementById('root'));
