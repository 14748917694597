import React from 'react';

import { Card, Input, PageHeader } from 'antd';
// eslint-disable-next-line
const { TextArea } = Input;
/* https://www.npmjs.com/package/react-loading */

class Song extends React.Component {

    componentDidMount() {
        const detailRequest = {
            id: this.props.match.params.song
        }
        this.props.getDetail(detailRequest)
    }

    render() {
        const song = this.props.song;
        return (
            <Card>
                <PageHeader
                    onBack={() => this.props.history.push('/')}
                    title={song.name}
                    subTitle={song.author}
                />
                <div className="whitespace-pre font-monospaced">{song.text}</div>
            
            </Card>
        );
    }
};

Song.defaultProps = {
    size: "128px",
    color: '#000',
    type: 'spin',
};

export default Song;