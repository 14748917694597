import React from 'react';

/* https://www.npmjs.com/package/react-loading */

import { Form, Icon, Input, Button} from 'antd';
import { Redirect } from 'react-router';
  
const { TextArea } = Input;

class EditSong extends React.Component {
    state = {
        redirectToDetail: false,
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
        console.log(this.props);
        // fetch detail
        const detailRequest = {
            id: this.props.match.params.song
        }
        this.props.getDetail(detailRequest)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
        this.props.form.validateFields((err, values) => {
        if (!err) {
            values.id = this.props.match.params.song
            console.log('Received values of form: ', values);
            this.props.songUpdate(values)
            this.setState(() => ({
                redirectToDetail: true
              }))
        }
        });
    }

    render() {
        if (this.state.redirectToDetail) {
            return <Redirect to={`/${this.props.match.params.song}`} />
        }
        const song = this.props.song;

        const {
        getFieldDecorator,
        } = this.props.form;

        // eslint-disable-next-line
        const emptyFieldDecorator = (name) => {
            return (getFieldDecorator(name, {rules: []}));
        }

        return (
        <Form layout="horizontal" onSubmit={this.handleSubmit}>
            <Form.Item>
            {getFieldDecorator('name', {
                rules: [],
                initialValue: song.name,
            })(
                <Input prefix={<Icon type="book" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name"  />
            )}
            </Form.Item>
            <Form.Item>
            {getFieldDecorator('author', {
                rules: [],
                initialValue: song.author,
            })(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Author" />
            )}
            </Form.Item>
            <Form.Item>
            {getFieldDecorator('content', {
                rules: [],
                initialValue: song.text,
            })(
                <TextArea placeholder="Autosize height based on content lines" autosize={{ minRows: 10, maxRows: 999 }} />
                )}
            </Form.Item>
            <Form.Item>
            <Button
                type="primary"
                htmlType="submit">
                Save
            </Button>
            </Form.Item>
        </Form>
        );
    }
}


const WrappedEditSong = Form.create({ name: 'edit_song' })(EditSong);

export default WrappedEditSong;