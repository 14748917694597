import Immutable from 'seamless-immutable';

/**
 * Basic redux state
 */
export default Immutable.from({
  songs: [],
  detail: false,
  authors: [],
  searchResult: [],
  catalogs : {
    airlines: [],
    locales: [],
    currencies: [],
  },
  user: {
    locale: [],
    currency: 'EUR'
  }
});

