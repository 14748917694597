import React from "react";

import { withRouter } from 'react-router-dom';

import { Input, Button, AutoComplete, Icon } from 'antd';


class SearchAutoComplete extends React.Component {

    componentDidMount() {
    }

    doClickOnSearchResult = (objectId,optionInstance, history, getSongDetail) => {
        // call get detail, as otherwise it would not be changed by song detail page itself
        const detailRequest = {
            id: objectId
        }
        getSongDetail(detailRequest)
        // do redirect to song detail
        history.push("/" + objectId)
    }

    render() {
        const dataSource = this.props.dataSource;
        const onSearch = this.props.onSearch;
        const history = this.props.history
        const getSongDetail = this.props.getSongDetail

        return (
            <AutoComplete
                dataSource={dataSource}
                className="global-search"
                size="large"
                style={{ width: '100%' }}
                placeholder="Search by song name, author or text"
                onSearch={onSearch}
                onSelect={(a,b) => this.doClickOnSearchResult(a,b, history, getSongDetail)}
                >
                <Input
                    suffix={(
                    <Button className="search-btn" size="large" type="primary">
                        <Icon type="search" />
                    </Button>
                    )}
                />
            </AutoComplete>
    );
    }
}

export default withRouter(SearchAutoComplete);