import React from 'react';

/* https://www.npmjs.com/package/react-loading */

import { Form, Icon, Input, Button} from 'antd';
// eslint-disable-next-line
import { Redirect } from 'react-router';

const { TextArea } = Input;

class CreateSong extends React.Component {
    state = {
        redirectToDetail: false,
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(e)
        this.props.form.validateFields((err, values) => {
        if (!err) {
            console.log('Received values of form: ', values);
            this.props.songCreate(values);
            this.setState(() => ({
                redirectToDetail: true
            }))
        }
        });
    }

    render() {

        const {
        getFieldDecorator,
        } = this.props.form;

        const emptyFieldDecorator = (name) => {
            return (getFieldDecorator(name, {rules: []}));
        }

        return (
        <Form layout="horizontal" onSubmit={this.handleSubmit}>
            <Form.Item>
            {getFieldDecorator('name', {
                rules: [],
            })(
                <Input prefix={<Icon type="book" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
            )}
            </Form.Item>
            <Form.Item>
            {getFieldDecorator('author', {rules: []})(
                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Author" />
            )}
            </Form.Item>
            <Form.Item>
                {emptyFieldDecorator('content')(
                <TextArea placeholder="Autosize height based on content lines" autosize={{ minRows: 10, maxRows: 999 }} />
                )}
            </Form.Item>
            <Form.Item>
            <Button
                type="primary"
                htmlType="submit">
                Save
            </Button>
            </Form.Item>
        </Form>
        );
    }
}


const WrappedCreateSong = Form.create({ name: 'create_song' })(CreateSong);

export default WrappedCreateSong;