import initialState from './model'

// import action types supported by the reducers
import { 
    ALL_SONGS_SUCCESS,
    ALL_AUTHORS_SUCCESS,
    SONG_SEARCH_SUCCESS,
    SONG_DETAIL_SUCCESS,
    SONG_CREATE_SUCCESS,
    SONG_UPDATE_SUCCESS,
    SONG_DELETE_SUCCESS,
    API_CALL_FAILURE
 } from './index'

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_SONGS_SUCCESS:
            return state
                .without('error')
                .set('songs', action.payload);
        case ALL_AUTHORS_SUCCESS:
            return state
                .without('error')
                .set('authors', action.payload);
        case SONG_SEARCH_SUCCESS:
            return state
                .without('error')
                .set('searchResult', action.payload);
        case SONG_DETAIL_SUCCESS:
        case SONG_CREATE_SUCCESS:
        case SONG_UPDATE_SUCCESS:
            return state
                .without('error')
                .set('detail', action.payload);
        case SONG_DELETE_SUCCESS:
                return state
                    .without('error');
        case API_CALL_FAILURE:
            console.log(action);
            return state
                .set('error', action.payload);
        default:
            return state;
    }
}

//const successState = (state) => state.without('error');

export default rootReducer;
  