
// action types
export const API_CALL_FAILURE = 'API_CALL_FAILURE'

// action creators
export const ALL_SONGS = 'ALL_SONGS'
export const ALL_SONGS_SUCCESS = 'ALL_SONGS_SUCCESS'
export const ALL_AUTHORS = 'ALL_AUTHORS'
export const ALL_AUTHORS_SUCCESS = 'ALL_AUTHORS_SUCCESS'
export const SONG_SEARCH = 'SONG_SEARCH'
export const SONG_SEARCH_SUCCESS = 'SONG_SEARCH_SUCCESS'
export const SONG_DETAIL = 'SONG_DETAIL'
export const SONG_DETAIL_SUCCESS = 'SONG_DETAIL_SUCCESS'
export const SONG_CREATE = 'SONG_CREATE'
export const SONG_CREATE_SUCCESS = 'SONG_CREATE_SUCCESS'
export const SONG_UPDATE = 'SONG_UPDATE'
export const SONG_UPDATE_SUCCESS = 'SONG_UPDATE_SUCCESS'
export const SONG_DELETE = 'SONG_DELETE'
export const SONG_DELETE_SUCCESS = 'SONG_DELETE_SUCCESS'

// action creators
export const allSongs = data => ({
    type: ALL_SONGS,
    payload: data
})

export const allSongsSuccess = data => ({
    type: ALL_SONGS_SUCCESS,
    payload: data
})

export const allAuthors = data => ({
    type: ALL_AUTHORS,
    payload: data
})

export const allAuthorsSuccess = data => ({
    type: ALL_AUTHORS_SUCCESS,
    payload: data
})

export const songSearch = data => ({
    type: SONG_SEARCH,
    payload: data
})

export const songSearchSuccess = data => ({
    type: SONG_SEARCH_SUCCESS,
    payload: data
})

export const songDetail = data => ({
    type: SONG_DETAIL,
    payload: data
})

export const songDetailSuccess = data => ({
    type: SONG_DETAIL_SUCCESS,
    payload: data
})

export const songCreate = data => ({
    type: SONG_CREATE,
    payload: data
})

export const songCreateSuccess = data => ({
    type: SONG_CREATE_SUCCESS,
    payload: data
})

export const songUpdate = data => ({
    type: SONG_UPDATE,
    payload: data
})

export const songUpdateSuccess = data => ({
    type: SONG_UPDATE_SUCCESS,
    payload: data
})

export const songDelete = data => ({
    type: SONG_DELETE,
    payload: data
})

export const songDeleteSuccess = data => ({
    type: SONG_DELETE_SUCCESS,
    payload: data
})

export const apiCallFailure = error => ({
    type: API_CALL_FAILURE,
    payload: error
})

export { default as rootReducer } from './reducers'
export { default as rootEpic } from './epics'