import React from 'react';
import { List, Pagination } from "antd";
import { Link } from 'react-router-dom';

/* https://www.npmjs.com/package/react-loading */

class Search extends React.Component {

    fetchPageableSongs(number, size) {
        var pageable = {
            page: number,
            size: size
        }
        this.props.allSongs(pageable)
    }

    componentDidMount() {
        this.fetchPageableSongs(1, 20)
    }

    render() {
        const dataSongs = this.props.dataSongs;

        return (
            <div>
                SEARCH PAGE
                <ul>
                {this.props.dataSongs &&
                    this.props.dataSongs.content &&
                    <div>
                        <List
                            size="small"
                            header={<div>Header</div>}
                        //    footer={<Pagination></Pagination>}
                            bordered
                            dataSource={this.props.dataSongs.content}
                            renderItem={song => 
                                <Link to={song.objectId}>
                                    <List.Item>{song.name} ({song.author})</List.Item>
                                </Link> 
                            }
                        />
                        <Pagination 
                            pageSize={dataSongs.size}
                            current={dataSongs.number + 1}
                            total={dataSongs.totalElements}
                            showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                            onChange={(number, size) => {
                                this.fetchPageableSongs(number, size)
                            }}
                        />
                    </div>
                }
                </ul>
            </div>
        );
    }
};

Search.defaultProps = {
    size: "128px",
    color: '#000',
    type: 'spin',
};

export default Search;