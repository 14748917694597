import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

// import action creators and action types
import { 
    ALL_SONGS,
    allSongsSuccess,
    ALL_AUTHORS,
    allAuthorsSuccess,
    SONG_SEARCH,
    songSearchSuccess,
    SONG_DETAIL,
    songDetailSuccess,
    SONG_CREATE,
    songCreateSuccess,
    SONG_UPDATE,
    songUpdateSuccess,
    SONG_DELETE,
    songDeleteSuccess,
    apiCallFailure,
 } from './index'

const allSongsEpic = (action$) =>
    action$.pipe(
      ofType(ALL_SONGS),
      mergeMap(action => {
        var payload = action.payload;
        var queryParams = ""
        if (payload && payload.page && payload.size) {
          queryParams = Object.keys(payload).map(key => key + '=' + payload[key]).join('&')
          console.log("pageable is set " + queryParams)
        }
        return ajax.getJSON(`/api/songs?${queryParams}`)
          .pipe(
            map(response => allSongsSuccess(response)),
            catchError(error => of(apiCallFailure(error)))
          );
 })
);

const allAuthorsEpic = (action$) =>
    action$.pipe(
      ofType(ALL_AUTHORS),
      mergeMap(action => {
        return ajax.getJSON(`/api/songs/authors`)
          .pipe(
            map(response => allAuthorsSuccess(response)),
            catchError(error => of(apiCallFailure(error)))
          );
 })
);

const songSearchEpic = (action$) =>
    action$.pipe(
      ofType(SONG_SEARCH),
      mergeMap(action => {
        console.log(action.payload)
        return ajax.getJSON(`/api/songs/search?query=${action.payload}`)
          .pipe(
            map(response => songSearchSuccess(response)),
            catchError(error => of(apiCallFailure(error)))
          );
 })
);

const songDetailEpic = (action$) =>
    action$.pipe(
      ofType(SONG_DETAIL),
      mergeMap(action => {
        return ajax.getJSON(`/api/songs/${action.payload.id}`)
          .pipe(
            map(response => songDetailSuccess(response)),
            catchError(error => of(apiCallFailure(error)))
          );
 })
);

const songCreateEpic = (action$) =>
  action$.pipe(
    ofType(SONG_CREATE),
    mergeMap(action => {
      const { author, name, content } = action.payload;
      const postData = {
        name : name,
        author : author,
        text : content
      }
      return ajax
        .post(`/api/songs`, JSON.stringify(postData), {
          'Content-Type': 'application/json',
        })
        .pipe(
          map(response => songCreateSuccess(response)),
          catchError(error => of(apiCallFailure(error)))
        );
})
);

const songUpdateEpic = (action$) =>
  action$.pipe(
    ofType(SONG_UPDATE),
    mergeMap(action => {
      const { author, name, content, id } = action.payload;
      const postData = {
        name : name,
        author : author,
        text : content
      }
      console.log("will update song")
      console.log(postData)
      return ajax
        .put(`/api/songs/${id}`, JSON.stringify(postData), {
          'Content-Type': 'application/json',
        })
        .pipe(
          map(response => songUpdateSuccess(response)),
          catchError(error => of(apiCallFailure(error)))
        );
})
);

const songDeleteEpic = (action$) =>
  action$.pipe(
    ofType(SONG_DELETE),
    mergeMap(action => {
      const { id } = action.payload;
      return ajax
        .delete(`/api/songs/${id}`, {
          'Content-Type': 'application/json',
        })
        .pipe(
          map(response => songDeleteSuccess(response)),
          catchError(error => of(apiCallFailure(error)))
        );
})
);

export default combineEpics(
  allSongsEpic,
  allAuthorsEpic,
  songSearchEpic,
  songDetailEpic,
  songCreateEpic,
  songUpdateEpic,
  songDeleteEpic,
);