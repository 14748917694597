import React from "react";

import { HashRouter, Route, Link, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Search, Song, EditSong, CreateSong } from '.';
import { SearchAutoComplete } from '../components';

import { allSongs, allAuthors, songDetail, songCreate, songSearch, songUpdate } from '../redux'
// eslint-disable-next-line
import { Layout, Menu, Input, Row, Col, Button, AutoComplete, Icon } from 'antd';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

class Main extends React.Component {

    componentDidMount() {
        this.props.allAuthors()
    }

    render() {
        const allSongs = this.props.allSongs;
        const songCreate = this.props.songCreate;
        const songDetail = this.props.songDetail;
        const dataSongs = this.props.dataSongs;
        const dataDetail = this.props.dataDetail;
        const songSearch = this.props.songSearch;
        const songUpdate = this.props.songUpdate;
        const searchResult = this.props.searchResult

        return (
            <HashRouter>
            <Layout className="layout">
                <Header>
                    <Row>
                        <Col span={12}>
                            <Menu
                                theme="dark"
                                mode="horizontal"
                                defaultSelectedKeys={['1']}
                                style={{ lineHeight: '64px' }}
                                >
                                <Menu.Item key="1"><Link to="/">SongBook</Link></Menu.Item>
                                <Menu.Item key="2"><Link to="/add">Add</Link></Menu.Item>
                            </Menu>
                        </Col>
                        <Col span={12}>
                            <div className="global-search-wrapper" style={{ width: 500 }}>
                                <SearchAutoComplete
                                    dataSource={searchResult.map(it => ({value:it.objectId,key: it.objectId, text:it.name + " (" + it.author + ")"}))}
                                    onSearch={value => songSearch(value)}
                                    getSongDetail={songDetail}
                                    />
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Content className="chordbook-content">
                    <Row>
                        <Col span={4}>
                            <Menu
                                theme="dark"
                             //   onClick={this.handleClick}
                                style={{ width: 256 }}
                                defaultOpenKeys={['sub1']}
                       //         selectedKeys={[this.state.current]}
                                mode="inline"
                                >
                                <SubMenu
                                    key="sub1"
                                    title={
                                    <span>
                                        <span>Navigation One</span>
                                    </span>
                                    }
                                >
                                    <Menu.Item key="1">Option 1</Menu.Item>
                                    <Menu.Item key="2">Option 2</Menu.Item>
                                    <Menu.Item key="3">Option 3</Menu.Item>
                                    <Menu.Item key="4">Option 4</Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="sub2"
                                    title={
                                    <span>
                                        <span>Navigation Two</span>
                                    </span>
                                    }
                                >
                                    <Menu.Item key="5">Option 5</Menu.Item>
                                    <Menu.Item key="6">Option 6</Menu.Item>
                                    <SubMenu key="sub3" title="Submenu">
                                    <Menu.Item key="7">Option 7</Menu.Item>
                                    <Menu.Item key="8">Option 8</Menu.Item>
                                    </SubMenu>
                                </SubMenu>
                                <SubMenu
                                    key="sub4"
                                    title={
                                    <span>
                                        <span>Navigation Three</span>
                                    </span>
                                    }
                                >
                                    <Menu.Item key="9">Option 9</Menu.Item>
                                    <Menu.Item key="10">Option 10</Menu.Item>
                                    <Menu.Item key="11">Option 11</Menu.Item>
                                    <Menu.Item key="12">Option 12</Menu.Item>
                                </SubMenu>
                            </Menu>
                        </Col>
                        <Col span={12}>
                            <Switch>
                                <Route path="/" exact render={() => <Search allSongs={allSongs} dataSongs={dataSongs} />} />
                                <Route path="/add" render={({history}) => <CreateSong songCreate={songCreate} history={history} />} />
                                <Route path="/:song/edit" render={({match, history}) => <EditSong match={match} songUpdate={songUpdate} getDetail={songDetail} song={dataDetail} history={history} />} />
                                <Route path="/:song" render={({match, history}) => <Song match={match} getDetail={songDetail} song={dataDetail} history={history}/>} />
                            </Switch>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            </HashRouter>
    );
    }
}

// mapovaci funkce na stav
const mapStateToProps = (state) => ({
    dataSongs : state.songs,
    dataDetail : state.detail,
    searchResult : state.searchResult,
})


const mapDispatchToProps = {
    allSongs,
    allAuthors,
    songDetail,
    songCreate,
    songSearch,
    songUpdate,
};
  
  // connect obaluje komponentu, umozni ji do props dat state z reduxu
  // pouziva mapovaci funkci
  // fluent vraci funkci a tej se da zase funkce
export default connect(mapStateToProps, mapDispatchToProps)(Main);